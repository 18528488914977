<template lang="pug">
section.section.section-first
    TheHeader
    .container.first-content
        h1.title
            span.title-main Розыгрыш автомобиля
            span.title-main-brand Honda 
            span.title-main-model  N Wagon
</template>

<script>
import TheHeader from './Header.vue'

export default {
	components: {
		TheHeader
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.section {
    &.section-first {
        background: url('../assets/img/footer-background.jpg') center / cover no-repeat #052B51;
        padding-top: 0;
        padding-bottom: 0;  

        .first-content {
            --first-bottom: 80px;
            margin-top: 64px;
            margin-bottom: var(--first-bottom);
            @media (--viewport-md) {
                --first-bottom: 96px;
            }
            @media (--viewport-lg) {
                --first-bottom: 104px;
            }
            @media (--viewport-xl) {
                --first-bottom: 112px;
            }
        }
        
        .title {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            
            &-main {
                --first-title-size: 56px;
                --first-title-sub: calc(40em / 56);
                width: 100%;
                font-family: var(--font-condensed);
                font-size: var(--first-title-size);
                font-weight: bold;
                text-transform: uppercase;
                text-align:  center;
                line-height: 1em;
                margin-bottom: 4px;
                color: #fff;
                @media (--viewport-md) {
                    --first-title-size: 60px;
                }
                @media (--viewport-lg) {
                    --first-title-size: 65px;
                }
                @media (--viewport-xl) {
                    --first-title-size: 70px;
                }

                &-brand {
                    width: auto;
                    font-family: var(--font-condensed);
                    font-weight: 200;
                    font-size: var(--first-title-sub);
                    text-transform: uppercase;
                    color: #fff;
                }

                &-model {
                    width: auto;
                    font-family: bebas-neue-pro-semiexpanded, sans-serif;
                    font-weight: bold;
                    font-size: var(--first-title-sub);
                    text-transform: uppercase;
                    margin-left: 4px;
                    margin-top: -1px;
                    color: #fff;
                }
            }
        }
    }
}
</style>
