<template lang="pug">
section.example
    .section.example-content(
        v-for="(content, index) in contents"
    )   
        TextBg {{ content.bg }}
        TheTitle(
            :title="content.title.title"
            :titleSub="content.title.titleSub"
        )
        Video(
            :link="content.video.link"
            :img="content.video.img"
            @click.native="openLightbox(index)"
        )
</template>

<script>
import TheTitle from './Title.vue'
import Video from './Video.vue'
import TextBg from './BackgroundText.vue'
import { ref } from 'vue'

export default {
    components: {
        TheTitle,
        Video,
        TextBg
    },

    setup() {
        const contents = ref([
            {
                title: {
                    title: 'Вот пример',
                    titleSub: 'Как снять обзор'
                },
                bg: 'Honda',
                video: {
                    link: 'https://www.youtube.com/watch?v=aHFofxZslFY&feature=youtu.be',
                    img: require('../assets/img/video-2.jpg')
                }
            },
            {
                title: {
                    title: 'Вот инструкция',
                    titleSub: 'Как отправить видео'
                },
                bg: 'NWGN',
                video: {
                    link: 'https://www.youtube.com/watch?v=KGamBUrmQ-s&feature=youtu.be',
                    img: require('../assets/img/video-1.jpg')
                }
            }
        ])

        require('fslightbox')
        const lightbox = new FsLightbox()
        lightbox.props.sources = contents.value.map(x => x.video.link)
        
        const openLightbox = (i) => { 
            lightbox.open(i) 
        }

        return {
            contents,
            openLightbox
        }
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.example {
    --example-width: 100%;
    --example-padding-right: 48px;
    --example-title-size: 34px;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    @media (--viewport-md) {
        --example-width: 50%;
        --example-title-size: 34px;
    }
    @media (--viewport-lg) {
        --example-padding-right: 56px;
        --example-title-size: 40px;
    }
    @media (--viewport-xl) {
        --example-title-size: 44px;
    }

    .example-content {
        width: var(--example-width);
        position: relative;
        @media (--viewport-md) { 
            padding-left: var(--example-padding-right)
        }

        &:first-of-type {
            z-index: 1;
        }

        &:last-of-type {
            z-index: 0;
        }

        .video {
            margin-top: 32px;
            @media (--viewport-md-max) {
                width: var(--container-xs);
                margin-left: auto;
                margin-right: auto;
            }
            @media (--viewport-md) {
                width: calc(100% - 56px);
                max-width: 500px;
                margin-top: 48px;
            }
            @media (--viewport-lg) {
                margin-top: 56px;
            }
        }

        .title {
            z-index: 1;
            font-size: var(--example-title-size);
            line-height: 1.3em;
            @media (--viewport-md) {
                align-items: flex-start;
            }

            >>> .title-sub {
                @media (--viewport-md) {
                    text-align: left;
                    font-size: .54em !important;
                }
            }
        }

        .background-text {
            text-align: left;
            right: auto;
            left: 0;
            transform: translate3d(0, -50%, 0);
            z-index: 0;
            width: 100%;
            color: #fff;
            opacity: .6;
        }

        &:first-of-type {
            background: linear-gradient(180deg, #F4F4F4 0%, rgba(255, 255, 255, 0) 100%);
            @media (--viewport-md) { 
                padding-right: var(--example-padding-right)
            }

            .background-text {
                text-align: right;
                left: auto;
                right: 0;
                color: #F4F4F4;
                opacity: .8;
            }

            .video {
                @media (--viewport-md) {
                    margin-left: auto;
                }
            }

            .title {
                @media (--viewport-md) {
                    align-items: flex-end;
                }

                >>> .title-sub {
                    @media (--viewport-md) {
                        text-align: right;
                    }
                }
            }
        }
    }
}
</style>
