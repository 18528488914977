<template lang="pug">
svg(
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
)
    g(:fill="iconColor")
        slot
</template>

<script>
export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		},
		viewBox: {
			type: String,
			required: true
		}
	}
}
</script>
