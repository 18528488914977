<template lang="pug">
section.background-text
    slot
</template>

<script>
export default {

}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.background-text {
    --bg-size: 300px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    color: #f8f8f8;
    opacity: 0.75;
    font-family: var(--font-condensed);
    font-weight: bold;
    font-size: 300px;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0px 0px 127px rgba(0, 0, 0, 0.07);
    @media (--viewport-md) {
        --bg-size: 340px;
    }
    @media (--viewport-lg) {
        --bg-size: 380px;
    }
    @media (--viewport-xl) {
        --bg-size: 400px;
    }
}
</style>
