<template lang="pug">
router-view
</template>

<script>
export default {

}
</script>

<style src="./assets/css/vendor.css"></style>
