<template lang="pug">
section.section.raffle
    TextBg Honda NWGN
    .container.raffle-container
        Title(
            title="Как участвовать"
            titleSub="В розыгрыше авто?"
        )
        p.raffle-text Снимите мини-обзор на автомобиль, который вы привезли через нашу компанию и пришлите его нам.
        p.raffle-text-sub Участвовать в розыгрыше могут только наши клиенты, которые покупали у нас автомобили.
</template>

<script>
import Title from './Title.vue'
import TextBg from './BackgroundText.vue'

export default {
    components: {
        Title,
        TextBg
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.raffle {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(233, 238, 243, 0.08) 0%, #FFFFFF 100%);

    .background-text {
        z-index: -1;
        transform: translate3d(-50%, -30%, 0);
        opacity: 1;
        width: 100%;
        text-shadow: 0px 0px 127px rgba(0, 0, 0, 0.1);
    }

    &-container {
        --raffle-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: var(--raffle-bottom);
        border-bottom: 1px dashed rgba(144, 156, 175, 0.5);
        @media (--viewport-lg) {
            --raffle-bottom: 40px;
        }
    }

    .title {
        margin-bottom: 40px;
    }

    p {
        text-align: center;
    }

    &-text {
        --raffle-text-size: 16px;
        font-size: var(--raffle-text-size);
        margin-bottom: 24px;    
        max-width: 440px;
        line-height: 1.5em;
        @media (--viewport-md) {
            --raffle-text-size: 17px;
        }
        @media (--viewport-xl) {
            --raffle-text-size: 18px;
        }

        &-sub {
            --raffle-textsub-size: 15px;
            font-size: var(--raffle-textsub-size);
            max-width: 340px;
            opacity: .5;
            line-height: 1.5em;
            @media (--viewport-md) {
                --raffle-textsub-size: 14px;
            }
            @media (--viewport-lg) {
                --raffle-textsub-size: 15px;
            }
            @media (--viewport-xl) {
                --raffle-textsub-size: 16px;
            }
        }
    }
}
</style>
