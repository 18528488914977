<template lang="pug">
#home
    TheFirst
    TheRaffle
    TheGallery
    TheVideo
    TheExample
    TheRequire
    TheTotal
    Footer
</template>

<script>
import TheFirst from '../components/TheFirst.vue'
import TheRaffle from '../components/TheRaffle.vue'
import TheGallery from '../components/TheGallery.vue'
import TheVideo from '../components/TheVideo.vue'
import TheExample from '../components/TheExample.vue'
import TheRequire from '../components/TheRequire.vue'
import TheTotal from '../components/TheTotal.vue'
import Footer from '../components/Footer.vue'

export default {
	components: {
        TheFirst,
        TheRaffle,
        TheGallery,
        TheVideo,
        TheExample,
        TheRequire,
        TheTotal,
        Footer
	}
}
</script>

<style scoped>
.require {
    margin-top: -48px;
}
</style>
