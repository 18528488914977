<template lang="pug">
section.section.total
    TextBg Honda NWGN
    .container.total-container
        TheTitle(
            title="Итоги конкурса"
            titleSub="Подведем 1 августа 2021 года в оффлайн формате"
        )
        ul.total-list
            li.total-list-item(v-for="item in items")
                .total-list-content(v-html="item")
</template>

<script>
import TheTitle from './Title.vue'
import TextBg from './BackgroundText.vue'
import { ref } from 'vue'

export default {
    components: {
        TheTitle,
        TextBg
    },

    setup() {
        const items = ref([
            'Сам розыгрыш будет транслироваться: в Instagram: <a href="https://instagram.com/sferacar.ru" target="_blank">https://instagram.com/sferacar.ru</a> на YouTube: <a href="https://youtube.com/channel/UC2o3j-f6OdifJoDFMgn4vpQ" target="_blank">https://youtube.com/channel/UC2o3j-f6OdifJoDFMgn4vpQ</a>',
            'Каждому участнику будет присвоен уникальный номер',
            'Все номера участников соберем в мешок и вытягиваем любой случайный номер. Чей номер вытянули - тот и победил!',
            'Если победитель не сможет сам забрать свой приз, то мы отправим его на автовозе в его город!'
        ])

        return {
            items
        }
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.total {
    position: relative;
    overflow: hidden;

    .background-text {
        z-index: 0;
        font-size: var(--bg-size);
        transform: translate3d(-50%, -30%, 0);
        width: 100%;
        opacity: 1;
        color: rgba(246, 246, 246, 0.7);
        text-shadow: 0px 0px 127px rgba(0, 0, 0, 0.03);
    }

    &.section {
        --total-padding: var(--section-padding);
        padding-top: var(--total-padding);
        padding-bottom: var(--total-padding);
        background: linear-gradient(180deg, #F4F4F4 0%, rgba(255, 255, 255, 0) 100%);
        @media (--veiwport-md) {
            --total-padding: 80px;
        }
        @media (--viewport-lg) {
            --total-padding: 96px;
        }
        @media (--viewport-xl) {
            --total-padding: 112px;
        }
    }

    .container {
        z-index: 1;
    }

    .title {
        --total-title-bottom: 40px;
        margin-bottom: var(--total-title-bottom);
        line-height: 1.3em;
        @media (--viewport-md) {
            --total-title-bottom: 48px;
        }
        @media (--viewport-lg) {
            --total-title-bottom: 56px;
        }
        @media (--viewport-xl) {
            --total-title-bottom: 72px;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin: 0;
        @media (--viewport-md) {
            flex-direction: row;
            justify-content: space-between;
        }

        &-item {
            --right: 40px;
            display: block;
            align-items: center;
            list-style-type: none;
            margin-bottom: 24px;
            @media (--viewport-md) {
                flex-direction: column;
                margin-right: var(--right);
                margin-bottom: 0;
            }
            @media (--viewport-lg) {
                --right: 48px;
            }

            &:before {
                content: '';
                display: block;
                height: 1px;
                width: 24px;
                border-top: 1px dashed rgba(144, 156, 175, 0.48);
                margin-right: 24px;
                @media (--viewport-md) {
                    transform: none;
                    height: 40px;
                    width: 1px;
                    border-right: 1px dashed rgba(144, 156, 175, 0.48);
                    margin-bottom: 40px;
                    margin-right: auto
                }
            }

            &:last-of-type {
                margin-right: 0 !important;
            }
            
            span {
                color: var(--color-text);
                font-weight: 300;
                font-size: 16px;
                opacity: .9;
                @media (--viewport-xl) {
                    font-size: 17px;
                }
            }

            >>> a {
                color: #065eab;
                font-weight: 500;
            }
        }
    }

    .title {
        >>> .title-sub {
            font-size: .64em;
        }
    }
}
</style>
