<template lang="pug">
section.gallery.section
    .container.gallery-container
        form.gallery-form
            .gallery-type(v-for="input in inputs")
                input.gallery-form-input(
                    v-model="galleryType"
                    type="radio" 
                    name="gallery-type" 
                    :value="input.value"
                    :id="input.id"
                )
                label.gallery-form-label(
                    :for="input.id"
                ) {{ input.label }}
        .gallery-images
            img.gallery-image-first(
                :src="images[galleryType][0]"
                @click="openLightbox(index)"
            )
            .gallery-images-sidebar
                template(v-for="(image, index) in images[galleryType]")
                    .gallery-images-img(v-if="index !== 0")
                        img(
                            v-if="index !== 0"
                            :src="image"
                            @click="openLightbox(index)"
                        )
</template>

<script>
import { ref, computed, onMounted, methods } from 'vue'

export default {    

    setup() {
        const galleryType = ref('exterior')
        const images = ref({})
        const inputs = ref([
            {
                id: 'gallery-exterior',
                value: 'exterior',
                label: 'Экстрьер NWGN'
            },
            {
                id: 'gallery-salon',
                value: 'salon',
                label: 'Салон NWGN'
            }
        ])
        const galleryStyle = ref({})

        const getImages = (length, name) => {
            let arr = []
            for ( let i = 1; i <= length; i++ ) {
                let path = require(`../assets/img/${name}-${i}.jpg`)
                arr.push(path)
            }
            return arr
        }

        images.value.exterior = getImages(10, 'exterior')
        images.value.salon = getImages(9, 'salon')        

        require('fslightbox')
        const lightbox = {
            salon: new FsLightbox(),
            exterior: new FsLightbox()
        }
        lightbox.exterior.props.sources = images.value.exterior
        lightbox.salon.props.sources = images.value.salon
        
        const openLightbox = (i) => { 
            lightbox[galleryType.value].open(i) 
        }

        return {
            inputs,
            images,
            galleryType,
            openLightbox,
        }
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.gallery {
    &.section {
        --gallery-top: 24px;
        --gallery-bottom: 40px;
        padding-top: var(--gallery-top);
        padding-bottom: var(--gallery-bottom);
        @media (--viewport-md) {
            --gallery-top: 32px;
            --gallery-bottom: 48px;
        }
        @media (--viewport-lg) {
            --gallery-bottom: 56px;
        }
        @media (--viewport-xl) {
            --gallery-bottom: 64px;
        }
    }

    &-images {
        --gallery-first: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        margin-top: 24px;
        @media (--viewport-md) {
            --gallery-first: 50%;
        }

        &-img {
            --gallery-img: 25%;
            width: var(--gallery-img);
            @media (--viewport-md) {
                width: calc(100% / 3);
            }
        }

        img {   
            display: block;
            position: relative;
            height: auto;
            width: 100%;
        }

        &-sidebar {
            flex-grow: ;
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            @media (--viewport-md) {
                width: calc(100% - var(--gallery-first));
            }
        }
    }

    .gallery-image {
        &-first {
            display: block;
            height: 100%;
            width: var(--gallery-first);
        }
    }

    &-type {
        margin-right: 24px;

        &:last-of-type {
            margin-right: 0;
        }
    }
    
    &-form {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        @media (--viewport-md) {
            width: auto;
            justify-content: flex-start;
        }

        &-input {
            display: none;

            &:checked {
                ~ .gallery-form-label {
                    font-weight: bold;
                }
            }
        }

        &-label {
            --gallery-size: 20px;
            font-family: var(--font-condensed);
            font-weight: 300;
            font-size: var(--gallery-size);
            text-transform: uppercase;
            @media (--viewport-md) {
                --gallery-size: 22px;
            }
            @media (--viewport-lg) {
                --gallery-size: 24px;
            }
            @media (--viewport-xl) {
                --gallery-size: 26px;
            }
        }
    }
}
</style>
