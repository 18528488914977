<template lang="pug">
.video
    .video-iframe(:style="style")
</template>

<script>
import { ref } from 'vue'

export default {
    props: {
        link: {
            type: String,
            required: true
        },
        img: {
            type: String,
            required: true
        }
    },

    setup(props) {
        const style = ref({})
        style.value.background = `url(${props.img}) center / 100% auto no-repeat`

        return {
            style
        }
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.video {
    --video-width: 100%;
    --video-padding-bottom: 60%;
    width: var(--video-width);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        display: block;
        background: url('../assets/img/video-icon.svg') center / 64px no-repeat;
        width: 100%;
        height: 100%;
        @media (--viewport-lg) {
            background-size: 72px;
        }
    }

    &-iframe {
        width: 100%;    
        padding-bottom: var(--video-padding-bottom);
    }
}
</style>
