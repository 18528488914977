<template lang="pug">
section.section.require
    TextBg Honda NWGN
    .container.require-container
        .require-title
            TheTitle(
                title="Требования"
                titleSub="К видео"
            )
        ul.require-list(v-for="(list, listIndex) in lists")
            li.require-list-item(v-for="(item, index) in list")
                span.require-list-number {{ listIndex === 0 ? index + 1 : +index + +(lists[listIndex - 1].length + 1) }}
                p.require-list-text {{ item }}
</template>

<script>
import TheTitle from './Title.vue'
import TextBg from './BackgroundText.vue'
import { ref } from 'vue'

export default {
    components: {
        TheTitle,
        TextBg
    },

    setup() {
        const lists = ref([
            [
                'В начале обзора представьтесь и назовите номер кузова (он находится под капотом или на дублирующей табличке на стойке двери)',
                'Расскажите историю покупки машины. Почему решили купить авто с аукциона и именно эту модель? Какие у неё интересные особенности?',
                'Почему вы выбрали нашу компанию, кто был ваш менеджер, сколько торговали авто, довольны ли своим выбором?',
                'Вы покупали несколько автомобилей и до сих пор ими владеете? Снимите несколько обзоров, повысив свои шансы на победу.',
            ],
            [
                'Снимать можно на обычный телефон, как в формате селфи, так и с помощью другого человека.',
                'Будьте внимательны со звуком, Ваш голос должно быть слышно!',
                'Длительность до 10 минут. Видео СТРОГО в горизонтальном формате!',
                `Загрузите видео в любой облачный сервис и отправьте ссылку - на номер в WhatsApp +7 (999) 613 83 68`
            ]
        ])

        return {
            lists
        }
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.require {
    position: relative;

    &-container {
        display: flex;
        flex-flow: row wrap;
    }

    &-title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .title {
            --title-margin: 32px;
            width: auto;
            margin-left: var(--title-margin);
            margin-right: var(--title-margin);
            @media (--viewport-md) {
                --title-margin: 32px;
            }
            @media (--viewport-lg) {
                --title-margin: 48px;
            }
            @media (--viewport-xl) {
                --title-margin: 56px;
            }
        }

        &:before,
        &:after {
            content: '';
            flex-grow: 1;
            display: block;
            border-bottom: 1px dashed rgba(144, 156, 175, 0.48);
            height: 1px;
        }
    }

    .background-text {
        z-index: -1;
        font-size: var(--bg-size);
        transform: translate3d(-50%, -30%, 0);
        width: 100%;
        color: #fff;
        text-shadow: 0px 0px 127px rgba(0, 0, 0, 0.03);
    }

    &-list {
        --top: 48px;
        display: flex;
        padding-left: 0;
        margin: 0;
        flex-direction: column;
        @media (--viewport-md) {
            --top: 56px;
            width: 50%;
            padding-right: 56px;
            margin-top: var(--top);
        }
        @media (--viewport-lg) {
            --top: 64px;
        }
        @media (--viewport-xl) {
            --top: 88px;
        }

        &:first-of-type {
            margin-top: var(--top);

            .require-list-item {
                justify-content: flex-end;
            }
        }

        &:last-of-type {
            @media (--viewport-md) {
                padding-left: 56px;
            }
        }

        &-item {
            --item-bottom: 32px;
            display: flex;
            list-style-type: none;
            justify-content: flex-start;
            margin-bottom: var(--item-bottom);
            @media (--veiwport-md) {
                --item-bottom: 40px;
            }
        }

        &-number {
            --number-size: 40px;
            display: block;
            width: auto;
            font-family: var(--font-condensed);
            font-weight: bold;
            font-size: var(--number-size);
            @media (--viewport-md) {
                --number-size: 44px;
            }
            @media (--viewport-lg) {
                --number-size: 50px;
            }
            @media (--viewport-xl) {
                --number-size: 56px;
            }
        }

        &-text {
            --require-text: 16px;
            --require-text-margin: 24px;
            font-family: var(--font-sans-serif);
            font-size: var(--require-text);
            font-weight: 300;
            color: var(--color-text);
            margin-left: var(--require-text-margin);
            max-width: 320px;
            opacity: .7;
            @media (--viewport-md) {
                --require-text: 17px;
                --require-text-margin: 32px;
            }
        }
    }
}
</style>
