<template lang="pug">  
IconBase.logotype(
    iconName="Sferacar Logotype"
    :viewBox="'0 0 181 31'"
)
    path(d='M23.2753 8.73209V9.70434H19.397V12.6211H23.2753V30.1442H26.184V12.6211H31.0319V9.70434H26.184V8.73209C26.184 7.44229 26.6946 6.20522 27.6036 5.29266C28.5126 4.3801 29.7456 3.86668 31.0319 3.86518V0.948425C28.9742 0.949924 27.0013 1.77065 25.5468 3.23021C24.0923 4.68976 23.2753 6.66871 23.2753 8.73209Z' fill='white')
    path(d='M64.0086 9.70441H63.0391C60.9814 9.70441 59.0079 10.5237 57.5523 11.9822C56.0968 13.4407 55.2783 15.419 55.2769 17.4824V30.1273H58.1912V17.4824C58.1927 16.1936 58.7039 14.958 59.6128 14.0466C60.5216 13.1353 61.7538 12.6227 63.0391 12.6212H65.9534V9.70441H64.0143H64.0086Z' fill='white')
    path(d='M140.622 9.70441H139.652C137.597 9.7074 135.627 10.5282 134.175 11.9865C132.722 13.4449 131.907 15.4215 131.907 17.4824V30.1273H134.815V17.4824C134.817 16.1936 135.328 14.958 136.237 14.0466C137.146 13.1353 138.378 12.6227 139.663 12.6212H142.572V9.70441H140.633H140.622Z' fill='white')
    path(d='M157.11 9.70441H156.146C154.089 9.70441 152.116 10.5239 150.661 11.9825C149.207 13.4412 148.39 15.4196 148.39 17.4824V30.1273H151.298V17.4824C151.3 16.1936 151.811 14.958 152.72 14.0466C153.629 13.1353 154.861 12.6227 156.146 12.6212H159.055V9.70441H157.116H157.11Z' fill='white')
    path(d='M80.497 9.70441H73.7044C72.1615 9.70441 70.6818 10.319 69.5908 11.413C68.4999 12.507 67.887 13.9908 67.887 15.5379H70.7957C70.7972 14.7648 71.1041 14.0238 71.6493 13.4771C72.1944 12.9304 72.9334 12.6227 73.7044 12.6212H80.497C81.2685 12.6212 82.0083 12.9285 82.5538 13.4755C83.0993 14.0225 83.4057 14.7643 83.4057 15.5379C83.4057 16.3115 83.0993 17.0534 82.5538 17.6004C82.0083 18.1474 81.2685 18.4547 80.497 18.4547H73.7044C72.1921 18.5019 70.7575 19.1374 69.7045 20.2268C68.6514 21.3162 68.0625 22.7738 68.0625 24.291C68.0625 25.8082 68.6514 27.2658 69.7045 28.3552C70.7575 29.4445 72.1921 30.0801 73.7044 30.1273H80.497C82.0404 30.1258 83.5201 29.51 84.6109 28.4151C85.7017 27.3202 86.3145 25.8358 86.3144 24.2882V15.5379C86.3144 14.7718 86.164 14.0133 85.8716 13.3055C85.5793 12.5978 85.1508 11.9547 84.6106 11.413C84.0704 10.8713 83.4291 10.4416 82.7233 10.1485C82.0175 9.85529 81.261 9.70441 80.497 9.70441ZM83.4057 24.2882C83.4043 25.0613 83.0973 25.8023 82.5522 26.349C82.007 26.8957 81.268 27.2034 80.497 27.2049H73.7044C72.933 27.2049 72.1931 26.8976 71.6476 26.3506C71.1021 25.8036 70.7957 25.0617 70.7957 24.2882C70.7957 23.5146 71.1021 22.7727 71.6476 22.2257C72.1931 21.6787 72.933 21.3714 73.7044 21.3714H80.497C81.5188 21.3715 82.5223 21.1004 83.4057 20.5857V24.2882Z' fill='white')
    path(d='M123.164 9.70441H116.399C115.635 9.70441 114.879 9.8553 114.173 10.1485C113.467 10.4416 112.826 10.8713 112.286 11.413C111.746 11.9547 111.317 12.5978 111.025 13.3055C110.732 14.0133 110.582 14.7718 110.582 15.5379H113.491C113.492 14.7648 113.799 14.0238 114.344 13.4771C114.889 12.9304 115.628 12.6227 116.399 12.6212H123.164C123.546 12.6212 123.924 12.6966 124.277 12.8432C124.63 12.9898 124.95 13.2046 125.221 13.4755C125.491 13.7463 125.705 14.0678 125.851 14.4217C125.997 14.7756 126.072 15.1549 126.072 15.5379C126.072 15.9209 125.997 16.3002 125.851 16.6541C125.705 17.008 125.491 17.3295 125.221 17.6004C124.95 17.8712 124.63 18.0861 124.277 18.2326C123.924 18.3792 123.546 18.4547 123.164 18.4547H116.399C114.887 18.5019 113.452 19.1375 112.399 20.2268C111.346 21.3162 110.757 22.7738 110.757 24.291C110.757 25.8082 111.346 27.2658 112.399 28.3552C113.452 29.4445 114.887 30.0801 116.399 30.1273H123.164C124.708 30.1273 126.189 29.5121 127.281 28.4171C128.373 27.322 128.987 25.8368 128.987 24.2882V15.5379C128.987 14.7714 128.836 14.0123 128.543 13.3042C128.251 12.5961 127.822 11.9528 127.281 11.411C126.74 10.8692 126.098 10.4397 125.392 10.1468C124.685 9.85401 123.928 9.70367 123.164 9.70441ZM126.078 24.2882C126.077 25.0623 125.769 25.8041 125.222 26.351C124.676 26.8978 123.936 27.2049 123.164 27.2049H116.399C115.628 27.2049 114.888 26.8976 114.343 26.3506C113.797 25.8036 113.491 25.0618 113.491 24.2882C113.491 23.5146 113.797 22.7727 114.343 22.2257C114.888 21.6787 115.628 21.3714 116.399 21.3714H123.164C124.187 21.3725 125.193 21.1014 126.078 20.5857V24.2882Z' fill='white')
    path(d='M38.7941 21.3884H45.5586C47.1015 21.3884 48.5811 20.7738 49.6721 19.6798C50.7631 18.5858 51.376 17.102 51.376 15.5548C51.376 14.0077 50.7631 12.5239 49.6721 11.4299C48.5811 10.3359 47.1015 9.72134 45.5586 9.72134H38.7941C37.2507 9.72134 35.7705 10.3358 34.6786 11.4296C33.5867 12.5234 32.9726 14.0072 32.9711 15.5548V24.3051C32.9726 25.8533 33.5865 27.3376 34.6783 28.4324C35.77 29.5271 37.2502 30.1428 38.7941 30.1443H45.5586C47.102 30.1428 48.5816 29.5269 49.6725 28.432C50.7633 27.3371 51.376 25.8528 51.376 24.3051H48.4786C48.4786 25.0787 48.1721 25.8206 47.6266 26.3676C47.0811 26.9146 46.3413 27.2219 45.5699 27.2219H38.8054C38.034 27.2219 37.2941 26.9146 36.7486 26.3676C36.2031 25.8206 35.8967 25.0787 35.8967 24.3051C35.8967 23.5315 36.2031 22.7896 36.7486 22.2427C37.2941 21.6957 38.034 21.3884 38.8054 21.3884H38.7941ZM35.8854 15.5548C35.8854 14.7813 36.1919 14.0394 36.7374 13.4924C37.2828 12.9454 38.0227 12.6381 38.7941 12.6381H45.5586C46.33 12.6381 47.0699 12.9454 47.6154 13.4924C48.1608 14.0394 48.4673 14.7813 48.4673 15.5548C48.4673 16.3284 48.1608 17.0703 47.6154 17.6173C47.0699 18.1643 46.33 18.4716 45.5586 18.4716H38.7941C37.7723 18.4707 36.7685 18.7419 35.8854 19.2573V15.5548Z' fill='white')
    path(d='M5.81743 12.6212H12.6101C13.381 12.6227 14.12 12.9304 14.6652 13.4771C15.2104 14.0238 15.5173 14.7648 15.5188 15.5379H18.4275C18.4275 13.9908 17.8146 12.507 16.7236 11.413C15.6326 10.319 14.1529 9.70441 12.6101 9.70441H5.84561C4.30273 9.70441 2.82305 10.319 1.73207 11.413C0.641091 12.507 0.0281852 13.9908 0.0281852 15.5379C0.0281852 17.0851 0.641091 18.5688 1.73207 19.6628C2.82305 20.7568 4.30273 21.3714 5.84561 21.3714H12.6101C12.992 21.3714 13.3703 21.4469 13.7232 21.5934C14.0761 21.74 14.3967 21.9549 14.6668 22.2257C14.9369 22.4966 15.1512 22.8181 15.2974 23.172C15.4435 23.5259 15.5188 23.9051 15.5188 24.2882C15.5188 24.6712 15.4435 25.0505 15.2974 25.4044C15.1512 25.7582 14.9369 26.0798 14.6668 26.3506C14.3967 26.6215 14.0761 26.8363 13.7232 26.9829C13.3703 27.1295 12.992 27.2049 12.6101 27.2049H5.84561C5.07463 27.2034 4.33565 26.8957 3.79049 26.349C3.24532 25.8023 2.93839 25.0613 2.9369 24.2882H0C-7.2318e-07 25.8358 0.612725 27.3202 1.70354 28.4151C2.79436 29.51 4.27403 30.1258 5.81743 30.1273H12.6101C14.1223 30.0801 15.5569 29.4445 16.61 28.3552C17.6631 27.2658 18.252 25.8082 18.252 24.291C18.252 22.7738 17.6631 21.3162 16.61 20.2268C15.5569 19.1374 14.1223 18.5019 12.6101 18.4547H5.84561C5.07417 18.4547 4.33433 18.1474 3.78884 17.6004C3.24335 17.0534 2.9369 16.3115 2.9369 15.5379C2.9369 14.7643 3.24335 14.0225 3.78884 13.4755C4.33433 12.9285 5.07417 12.6212 5.84561 12.6212H5.81743Z' fill='white')
    path(d='M95.0405 12.6212H101.833C102.604 12.6227 103.343 12.9304 103.888 13.4771C104.433 14.0238 104.74 14.7648 104.742 15.5379H107.667C107.667 13.9908 107.055 12.507 105.964 11.413C104.873 10.319 103.393 9.70441 101.85 9.70441H95.0405C93.4976 9.70441 92.0179 10.319 90.927 11.413C89.836 12.507 89.2231 13.9908 89.2231 15.5379V24.2882C89.2231 25.8358 89.8358 27.3202 90.9266 28.4151C92.0174 29.51 93.4971 30.1258 95.0405 30.1273H101.833C102.599 30.1288 103.357 29.9789 104.065 29.6861C104.773 29.3933 105.416 28.9634 105.958 28.4211C106.5 27.8787 106.93 27.2345 107.223 26.5253C107.516 25.8161 107.667 25.0559 107.667 24.2882H104.759C104.757 25.0613 104.45 25.8023 103.905 26.349C103.36 26.8956 102.621 27.2034 101.85 27.2049H95.0405C94.2695 27.2034 93.5306 26.8956 92.9854 26.349C92.4402 25.8023 92.1333 25.0613 92.1318 24.2882V15.5379C92.1333 14.7648 92.4402 14.0238 92.9854 13.4771C93.5306 12.9304 94.2695 12.6227 95.0405 12.6212Z' fill='white')
    path(d='M145.47 27.2048H142.561V30.1216H145.47V27.2048Z' fill='white')
    path(d='M177.477 9.70441V24.2882C177.475 25.0613 177.168 25.8023 176.623 26.349C176.078 26.8957 175.339 27.2034 174.568 27.2049H167.775C167.004 27.2034 166.265 26.8957 165.72 26.349C165.175 25.8023 164.868 25.0613 164.867 24.2882V9.70441H161.958V24.2882C161.958 25.8358 162.571 27.3202 163.661 28.4151C164.752 29.51 166.232 30.1258 167.775 30.1273H174.568C176.111 30.1258 177.591 29.51 178.682 28.4151C179.773 27.3202 180.385 25.8358 180.385 24.2882V9.70441H177.477Z' fill='white')
</template>

<script>
/* eslint-disable */ 
import IconBase from './IconBase.vue'

export default {
	components: {
		IconBase
	},

	props: {
		id: {
			type: String,
			required: true
		},
		icon: {
			type: Boolean,
			required: false,
			default: true
		}
	},  
}
</script>

<style>

</style>
