<template lang="pug">
footer.footer.section
    .footer-container.container
        TheTitle(
            title="Остались вопросы"
            titleSub="Свяжитесь с нами"
        )
        a.footer-link.footer-link-tel(href="tel:+79996138368") +7 (999) 613 83 68
        a.footer-link.footer-link-email(href="mailto:giveaway@sferacar.ru") giveaway@sferacar.ru
</template>

<script>
import TheTitle from './Title.vue'

export default {
    components: {
        TheTitle
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.footer {
    background: url('../assets/img/footer-background.jpg') center / cover no-repeat #052B51;

    &.section {
        --total-padding: var(--section-padding);
        padding-top: var(--total-padding);
        padding-bottom: var(--total-padding);
        @media (--veiwport-md) {
            --total-padding: 80px;
        }
        @media (--viewport-lg) {
            --total-padding: 96px;
        }
        @media (--viewport-xl) {
            --total-padding: 112px;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    &-link {
        --link-size: 28px;
        color: #fff;
        font-family: var(--font-condensed);
        font-size: var(--link-size);
        text-trasnsform: uppercase;
        margin-left: auto;
        margin-right: auto;
        @media (--viewport-md) {
            --link-size: 30px;
        }
        @media (--viewport-lg) {
            --link-size: 36px;
        }
        @media (--viewport-xl) {
            --link-size: 40px;
        }
    }

    .title {
        --bottom: 40px;
        color: #fff;
        margin-bottom: var(--bottom);
        @media (--viewport-lg) {
            --bottom: 48px;
        }

        >>> .title-sub {
            --title-size: .54em;
            @media (--viewport-md) {
                font-size: var(--title-size)
            }
        }
    }
}
</style>
