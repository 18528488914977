<template lang="pug">
p.title(v-if="title") {{ title }}
	span.title-sub(v-if="titleSub") {{ titleSub }}
</template>

<script>
export default {
	props: {
		title: { type: String, required: true },
		titleSub: { type: String, required: false }
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.title {
    --title-size: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-condensed);
    font-size: var(--title-size);
    font-weight: bold;
    color: var(--color-text);
    text-transform: uppercase;
    line-height: 1em;
    @media (--viewport-md) {
        --title-size: 44px;
    }
    @media (--viewport-lg) {
        --title-size: 48px;
    }
    @media (--viewport-xl) {
        --title-size: 56px;
    }

    &-sub {
        --title-sub-size: calc(49em / 56);
        font-size: var(--title-sub-size);
        font-family: var(--font-condensed);
        font-weight: 300;
        text-align: center;
    }
}
</style>
