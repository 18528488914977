<template lang="pug">  
IconBase(
    iconName="Telegram Logotype"
    :viewBox="'0 0 50 50'"
    fill="none"
)
    g#cf39a503-a407-4db3-8091-61e6eee3992d(data-name='Group 18')
        circle#d4ad24c8-4c27-43b9-82ad-c50bd1d09408(data-name='Ellipse 130' cx='25' cy='25' r='25' style='fill: #42bde9')
        g#66fa20d5-1e50-4674-8b55-cd01fdf289ce(data-name='Group 17')
            path#72a311fe-2815-4fc9-92f1-9b394d9bc6f2(data-name='Path 179' d='M37.16,13.78,32.7,36.29a1.66,1.66,0,0,1-2.16.9.76.76,0,0,1-.18-.09l-10.3-7.9-3.75-1.81L10,25.27s-1-.35-1.06-1.1S10,23,10,23l25.08-9.84S37.16,12.28,37.16,13.78Z' style='fill: #fff')
            path#56645724-4d99-4108-b4ae-2a4e10e0dbeb(data-name='Path 180' d='M19.26,36s-.3,0-.67-1.22-2.28-7.43-2.28-7.43l15.14-9.62s.87-.53.84,0c0,0,.16.1-.31.53S20.08,29,20.08,29' style='fill: #42bde9')
            path#8a64a777-bd00-4747-9b30-dc90a5128ef8(data-name='Path 181' d='M24,32.23,19.93,36a.72.72,0,0,1-.67.09l.79-6.9' style='fill: #42bde9')
</template>

<script>
/* eslint-disable */ 
import IconBase from './IconBase.vue'

export default {
	components: {
		IconBase
	},

	props: {
		id: {
			type: String,
			required: true
		},
		icon: {
			type: Boolean,
			required: false,
			default: true
		}
	},  
}
</script>

<style>

</style>
