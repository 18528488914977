<template lang="pug">
header.header 
    .container.header-container
        .header-left
            a(v-for="socialContact in socialContacts" :href="socialContact.link" target="_blank")
                IconWhatsapp(v-if="socialContact.type === 'whatsapp'" id="whatsapp")
                IconTelegram(v-if="socialContact.type === 'telegram'" id="telegram")
        .header-center
            span.header-logotype-text от компании
            IconLogo(id="header-logotype")
        a.header-right(href="tel:+79996138368") +7 (999) 613 83 68
            span Звонок по РФ бесплатный
</template>

<script>
import IconLogo from './IconLogo.vue'
import IconWhatsapp from './IconWhatsapp.vue'
import IconTelegram from './IconTelegram.vue'
import { ref } from 'vue'

export default {
	components: {
        IconLogo,
        IconWhatsapp,
        IconTelegram
    },
    
    setup() {
        const socialContacts = ref([
            {
                type: 'whatsapp',
                link: 'https://api.whatsapp.com/send?phone=79996138368'
            },  
            {
                type: 'telegram',
                link: 'tg://resolve?domain=sferacar_sales'
            }
        ])

        return {
            socialContacts
        }
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.header {
    --logo-width: 140px;
    @media (--viewport-md) {
        --logo-width: 150px;
    }
    @media (--viewport-lg) {
        --logo-width: 170px;
    }
    @media (--viewport-xl) {
        --logo-width: 180px;
    }
            
    &-container {
        --header-top: 32px;
        --header-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: var(--header-bottom);
        padding-top: var(--header-top);
        border-bottom: 1px dashed rgba(255, 255, 255, .2);
        @media (--viewport-md) {
            --header-top: 40px;
            --header-bottom: 32px;
        }
        @media (--viewport-lg) {
            --header-top: 48px;
            --header-bottom: 40px;
        }
        @media (--viewport-xl) {
            --header-top: 56px;
            --header-bottom: 48px;
        }
    }

    &-center {
        display: flex;
        flex-direction: column;

        svg {
            --logo-height: calc(31 / 181);
            width: var(--logo-width);
            height: calc(var(--logo-width) * var(--logo-height));
        }
    }

    &-logotype {
        &-text {
            --logo-text-size: 14px;
            width: auto;
            margin-left: auto;
            font-size: var(--logo-text-size);
            color: #fff;
            @media (--viewport-lg) {
                --logo-text-size: 15px;
            }
        }
    }

    &-right {
        display: none;
        @media (--viewport-md) {
            display: flex;
            flex-grow: 1;
            width: calc((100% - var(--logo-width)) / 2);
            flex-direction: column;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            align-items: flex-end;
            line-height: 1.4em;
        }

        span {
            font-size: calc(13em / 24);
            text-align: right;
        }
    }

    &-left {
        display: none;
        @media (--viewport-md) {
            display: flex;
            flex-grow: 1;
            width: calc((100% - var(--logo-width)) / 2);
        }

        a {
            width: 24px;
            height: 24px;
            margin-right: 16px;
        }
    }
}
</style>
