<template lang="pug">
section.section.video
    .container.video-container
        .video-icon-section
            IconBase.video-icon(
                iconName="Video Icon"
                :viewBox="'0 0 122 122'"
                @click.native="openLightbox"
            )
                path(d='M89.7131 61.162L49.5457 35.0065C48.8805 34.5733 48 35.0507 48 35.8445V88.1555C48 88.9493 48.8805 89.4267 49.5457 88.9935L89.7131 62.838C90.3188 62.4435 90.3188 61.5565 89.7131 61.162Z' fill='white')
                circle(fill="none" cx='61' cy='61' r='60.5' stroke='white' stroke-opacity='0.5')
        span.video-title(@click="openLightbox") Видеообращение Сергея Агапова
        span.video-text(@click="openLightbox") Генеральный директор ООО “Сферагрупп”
</template>

<script>
import IconBase from './IconBase.vue'

export default {
    components: {
        IconBase
    },
    setup() {
        require('fslightbox')
        const lightbox = new FsLightbox()
        lightbox.props.sources = ['https://www.youtube.com/watch?v=WiVmjV8RS5k&feature=youtu.be']
        lightbox.props.types [ 'youtube' ]
        
        const openLightbox = (i) => { 
            lightbox.open() 
        }

        return {
            openLightbox
        }
    }
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.video {
    background: url('../assets/img/video-background.jpg') center / cover no-repeat #052B51;

    &.section {
        --video-section-padding: 122px;
        padding-top: var(--video-section-padding);
        padding-bottom: var(--video-section-padding);
        @media (--viewport-md) {
            --video-section-padding: 160px;
        }
        @media (--viewport-lg) {
            --video-section-padding: 222px;
        }
        @media (--viewport-xl) {
            --video-section-padding: 264px;
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-title {
        --video-size: 22px;
        font-family: var(--font-condensed);
        font-size: var(--video-size);
        font-weight: bold;
        color: #fff;
        text-align: center;
        line-height: 1.5em;
        @media (--viewport-md) {
            --video-size: 25px;
        }
        @media (--viewport-lg) {
            --video-size: 30px;
        }
        @media (--viewport-xl) {
            --video-size: 35px;
        }
    }

    &-icon {
        --video-icon-size: 64px;
        width: var(--video-icon-size);
        height: var(--video-icon-size);
        margin-left: 48px;
        margin-right: 48px;
        @media (--viewport-md) {
            --video-icon-size: 80px;
        }
        @media (--viewport-lg) {
            --video-icon-size: 96px;
        }
        @media (--viewport-xl) {
            --video-icon-size: 122px;
        }

        &-section {
            --video-icon-bottom: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: var(--video-icon-bottom);
            max-width: 725px;
            margin-left: auto;
            margin-right: auto;
            @media (--viewport-md) {
                --video-icon-bottom: 48px;
            }
            @media (--viewport-lg) {
                --video-icon-bottom: 56px;
            }
            @media (--viewport-xl) {
                --video-icon-bottom: 64px;
            }

            &:before,
            &:after {
                content: '';
                flex-grow: 1;
                display: block;
                border-bottom: 1px dashed rgba(144, 156, 175, 0.3);
                height: 1px;
            }
        }
    }

    &-text {
        --video-size-sub: 15px;
        font-size: var(--video-size-sub);
        color: #fff;
        text-align: center;
        line-height: 1.5em;
        @media (--viewport-lg) {
            --video-size-sub: 16px;
        }
    }
}
</style>
