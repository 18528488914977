<template lang="pug">  
IconBase(
    iconName="Whatsapp"
    :viewBox="'0 0 24 24'"
)
    path(d='M0 24.2956L1.69567 18.3758C0.647963 16.6394 0.0976667 14.6695 0.100304 12.6649C0.100304 6.36483 5.46331 1.24136 12.0502 1.24136C13.6198 1.24144 15.1741 1.53722 16.6242 2.11181C18.0743 2.68641 19.3918 3.52856 20.5015 4.59017C21.6112 5.65177 22.4913 6.91204 23.0916 8.29897C23.6919 9.68591 24.0005 11.1723 24 12.6734C24 18.9734 18.6364 24.0975 12.0507 24.0975H12.0454C10.0505 24.0973 8.0874 23.6194 6.33519 22.7075L0 24.2956Z' fill='#20B038')
    path(d='M0 24.2956L1.69538 18.1505C0.647308 16.3466 0.0970827 14.3001 0.100267 12.2176C0.100267 5.67418 5.46314 0.354675 12.0498 0.354675C13.6196 0.354675 15.174 0.661806 16.6242 1.25852C18.0744 1.85524 19.392 2.72985 20.5017 3.83238C21.6114 4.93492 22.4916 6.24378 23.0918 7.6842C23.6921 9.12462 24.0007 10.6684 24 12.2273C23.9971 18.7707 18.636 24.0902 12.0498 24.0902H12.0453C10.0501 24.0901 8.08678 23.5936 6.33461 22.6461L0 24.2956ZM6.62796 20.4987L6.99121 20.7121C8.52175 21.612 10.2677 22.0867 12.0464 22.0867H12.0498C17.5244 22.0867 21.9798 17.6628 21.982 12.2261C21.9826 10.9308 21.7263 9.64806 21.2277 8.45114C20.7291 7.25421 19.9979 6.16655 19.076 5.25026C18.154 4.33396 17.0594 3.60699 15.8545 3.11085C14.6497 2.6147 13.3582 2.35911 12.0539 2.35866C6.57525 2.35866 2.11994 6.78143 2.11765 12.2181C2.11514 14.0746 2.64152 15.8939 3.63598 17.466L3.87261 17.8386L2.87166 21.4773L6.62796 20.4987Z' fill='white')
    path(fill-rule='evenodd' clip-rule='evenodd' d='M8.82507 7.09736C8.58648 6.588 8.3369 6.57802 8.10929 6.56922C7.92623 6.56101 7.71204 6.56159 7.49908 6.56159C7.338 6.56573 7.17955 6.60185 7.03369 6.66769C6.88783 6.73353 6.7577 6.82768 6.65149 6.9442C6.3598 7.25052 5.53845 7.98992 5.53845 9.49454C5.53845 10.9992 6.67773 12.4533 6.83699 12.6575C6.99626 12.8617 9.03682 16.0476 12.2679 17.2735C14.9529 18.2922 15.5021 18.0897 16.0836 18.0363C16.6651 17.9829 17.9649 17.2964 18.2303 16.5822C18.4958 15.868 18.4952 15.2566 18.4134 15.1286C18.3316 15.0007 18.1217 14.9244 17.8032 14.7718C17.4846 14.6193 15.9219 13.8787 15.6302 13.7772C15.3385 13.6757 15.1268 13.624 14.9144 13.9303C14.7021 14.2367 14.0937 14.9244 13.9076 15.1286C13.7214 15.3328 13.5366 15.3587 13.2186 15.2055C12.9007 15.0523 11.8762 14.7296 10.6618 13.688C9.71599 12.877 9.07526 11.8765 8.8922 11.5701C8.70913 11.2638 8.87267 11.1007 9.03194 10.9463C9.17473 10.809 9.34986 10.589 9.50913 10.4106C9.66839 10.2322 9.72087 10.1048 9.82705 9.90062C9.93323 9.6964 9.88014 9.51801 9.8002 9.36485C9.72026 9.21169 9.1076 7.70179 8.82507 7.09736Z' fill='white')

</template>

<script>
/* eslint-disable */ 
import IconBase from './IconBase.vue'

export default {
	components: {
		IconBase
	},

	props: {
		id: {
			type: String,
			required: true
		},
		icon: {
			type: Boolean,
			required: false,
			default: true
		}
	},  
}
</script>

<style>

</style>
